<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import html2pdf from 'html2pdf.js';
//import DatePicker from "vue2-datepicker";
//import CKEditor from "@ckeditor/ckeditor5-vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import Seat from './seat';
//import { format } from "date-fns";
//import router from "@/router/index.js";
//import Vue from "vue";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    //Seat,
    //Multiselect,
    //ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "liste des compagnies",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "liste",
          active: true,
        },
      ],
      tyMedia: [
        {
          name: "",
          value: 0,
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],
      coId: 0,
      line: {
        compagnieId: "",
        lieuDepart: {
          nom: "",
          latitude: "",
          longitude: ""
        },
        lieuArrive: {
          nom: "",
          latitude: "",
          longitude: ""
        },
        lieuEmbarquement: "",
        lieuDebarquement: "",
        prix: 0,
        horraire: [],
        daysOfWeek: [],
        isPromo: null,
        nombrePlace: "",
        wifi: false,
        toilet: false,
        siegeClinable: false,
        priseElectric: false,
        assurance: false,
        tailleBagage: "", //isIn:   ["GRAND","MOYEN","PETIT"]},
        assistancePMR: false,
      },

      days: [{ name: "L", value: "lundi" }, { name: "Ma", value: "mardi" }, { name: "Me", value: "mercredi" }, { name: "J", value: "jeudi" }, { name: "V", value: "vendredi" }, { name: "S", value: "samedi" }, { name: "D", value: "dimanche" },],
      lineId: 0,
      errorForm: "",
      userTypeSelect: "",
      messageSelect: null,
      mediaSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      value: null,
      value1: null,
      idUser: 0,
      dataUser: [],
      InactifData: [],
      ActifData: [],
      approvedCo: [],
      dataUserD: [],
      nbreUser: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      showLineModal: false,
      editLineModal: false,
      depTime: "",
      arrTime: "",
      fields: [
        { key: "designation", sortable: true, label: "Nom Compagnie" },
        { key: "tel", sortable: true, label: "Contact" },
        { key: "email", sortable: true, label: "Email" },
        { key: "tel", sortable: true, label: "Contact du dirigeant" },
        { key: "commission", sortable: true, label: "Commission", },
        { key: "action", label: "Actions" },
      ],
      fieldsFiltered: [
        { key: "designation", sortable: true, label: "Nom Compagnie" },
        { key: "tel", sortable: true, label: "Contact" },
        { key: "email", sortable: true, label: "Email" },
        { key: "tel", sortable: true, label: "Contact du dirigeant" },
        { key: "commission", sortable: true, label: "Commission", },
        { key: "sold", sortable: true, label: "Tickets Achetés" },
        { key: "action", label: "Actions" },
      ],
      lineField: [
        { key: "lieuDepart", sortable: true, label: "Départ" },
        { key: "lieuArrive", sortable: true, label: "Arrivee" },
        { key: "lieuEmbarquement", sortable: true, label: "Lieu d'embarquement" },
        { key: "lieuDebarquement", sortable: true, label: "Lieu de débarquemment" },
        { key: "horraire", sortable: true, label: "Horraires(Dep -Arr )", },
        { key: "daysOfWeek", sortable: true, label: "Jours", },
        { key: "prix", sortable: true, label: "Prix", },
        { key: "isPromo", label: "Promotion", },
        { key: "createdAt", sortable: true, label: "Créé le", },
        { key: "action", label: "Action", },


      ],
      lineFieldForm: [
        { key: "select", label: "Cocher" },
        { key: "lieuDepart", sortable: true, label: "Départ" },
        { key: "lieuArrive", sortable: true, label: "Arrivee" },
        { key: "lieuEmbarquement", sortable: true, label: "Embarquement" },
        { key: "daysOfWeek", sortable: true, label: "Jours", },
        { key: "horraire", sortable: true, label: "Horraires", },
        { key: "prix", sortable: true, label: "Prix", },
        { key: "isPromo", label: "Promotion", },


      ],
      fieldsCo: [
        { key: "designation", sortable: true, label: "Nom Compagnie" },
        { key: "tel", sortable: true, label: "Contact" },
        { key: "email", sortable: true, label: "Email" },
        { key: "tel", sortable: true, label: "Contact du dirigeant" },
        { key: "commission", sortable: true, label: "Commission", },
        { key: "sold", sortable: true, label: "Tickets Achetés", },
        { key: "ligne", label: "Ligne", },
        { key: "buy", sortable: true, label: "Vendre", },
        { key: "action", label: "Actions" },
      ],

      soldField: [
        { key: "user", sortable: true, label: "Client" },
        { key: "telephone", sortable: true, label: "Tel Client" },
        { key: "commission", sortable: true, label: "Tarif" },
        { key: "line", sortable: true, label: "Ligne" },
        { key: "places", sortable: true, label: "Places choisies" },
        { key: "nombrePlaces", sortable: true, label: "Place disponible", },
        //{ key: "compagnie", sortable: true, label: "Compagnie" },
        { key: "dateHeureReservation", sortable: true, label: "Date de réservation", },
        //{ key: "sold", sortable: true, label: "Date achat", },
        //{ key: "ligne", label: "Ligne", },
        { key: "action", label: "Actions" },
      ],

      userField: [
        { key: "firstname", sortable: true, label: "Prénom" },
        { key: "lastname", sortable: true, label: "Nom" },
        { key: "tel", sortable: true, label: "Téléphone" },

      ],
      confirmationModal: false,
      commissionModal: false,
      userToUse: 0,
      commission: 0,
      idSociety: 0,
      totalActif: 0,
      totalInactif: 0,
      showLine: null,
      showModal: false,
      soldModal: false,
      billetId: 0,
      soldData: [],
      buyModal: false,
      buy: {
        user: null,
        compagnieId: null,
        lineId: null,
        date: null,
        place: null,
        horraire: null,
      },
      billData: null,
      time: [],
      lineIdSelected: [],
      userFound: [],
      recherche: "",
      selectedUser: [],
      currentStep: 1,
      seatList: ["1A", "1B", "1D", "1E", "2A", "2B", "2D", "2E", "3A", "3B", "3D", "3E", "4A", "4B", "4D", "4E", "5A", "5B", "5D", "5E", "6A", "6B", "7A", "7B", "10A", "10B", "10D", "10E", "11A", "11B", "11D", "11E", "12A", "12B", "12D", "12E", "14A", "14B", "14D", "14E", "15A", "15B", "15D", "15E", "20A", "20B", "20D", "20E", "21A", "21B", "21D", "21E", "22A", "22B", "22D", "22E", "23A", "23B", "23D", "23E", "24A", "24B", "24D", "24E"],
      seatListTwo: [],
      selectedSeat: [],
      selectedTime: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    sortedGroupedSeats() {
      const sortedKeys = Object.keys(this.groupedSeats).sort();
      const sortedGroupedSeats = {};

      sortedKeys.forEach(key => {
        sortedGroupedSeats[key] = this.groupedSeats[key];
      });

      return sortedGroupedSeats;
    },

    groupedSeats() {
      const groupedSeats = {};

      this.seatListTwo.forEach(seat => {
        const type = seat.slice(-1);
        if (!groupedSeats[type]) {
          groupedSeats[type] = [];
        }
        groupedSeats[type].push(seat);
      });

      return groupedSeats;
    },

    rows() {
      return this.dataUser.length;
    },
    rowsSold() {

      return this.soldData.length;

    }
  },
  async mounted() {
    this.init();
    this.appCo();
  },
  methods: {

    async generatePDF() {
      const element = document.getElementById('pdfTicket'); // ou un élément spécifique comme document.getElementById('content')
      const opt = {
        margin: 10,
        filename: 'ma-page.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
      html2pdf().set(opt).from(element).save().catch(error => {
        console.error('Erreur lors de la génération du PDF:', error);
      });

      //html2pdf().from(element).set(opt).save();

      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf('blob');

        // 2. Convertir le PDF en Base64 (optionnel, dépend de votre API)
        const base64data = await this.blobToBase64(pdfBlob);

        return  base64data

        // if(base64data){
        //   const response = await apiRequest('POST','',{})
        // }
    },

    async  sendPdf  () {
  try {
    if(this.billData.id ==null){
       this.$toast.error('Une erreur est survenue')
      return
    }
    const formData = new FormData();
    const pdfBlob = await this.generatePDF();
    
    // Log the PDF Blob to verify its content
    console.log('PDF Blob:', pdfBlob);
    
    formData.append('billetId', this.billData.id);
    formData.append('file', pdfBlob, 'document.pdf'); // Append the Blob directly
    
    const response = await apiRequest('POST', 'compagnie/send-bill', formData);
    console.log(response)
    if (response.data && response.status == 200) {
      
      console.log("Bill sent data", response.data);
      // billModal.value =false
      // await closeBillModal()
      // billData.value.id = null
      this.$toast.success('Facture envoyée avec succès')
    }
  } catch (error) {
    console.error("Error sending bill:", error);
  }
},

    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    async populate(data) {
      this.billData = data;

    },
    async finalBuy() {
      try {
        const request = await apiRequest("POST", "dash/buy-bus-ticket", {
          user: this.buy.user,
          lineId: this.buy.lineId,
          nbrPlace: this.selectedSeat.length,
          dateDepart: this.buy.date,
          heureDepart: this.buy.horraire,
          sieges: JSON.stringify(this.selectedSeat)

        })

        if (request.status == 200 && request.data) {

          console.log("we are done", request.data);
          //this.billData =request.data
          await this.populate(request.data)
          this.generatePDF()
          this.$toast.success("TICKET VENDU");

        } else {
          console.log("response", request.data)
          this.$toast.error("Solde Insuffisant");
        }

      } catch (error) {
        console.log("here", error)
      }


    },
    sortSeatGroup(seatGroup) {
      return seatGroup.sort((a, b) => {
        const seatA = parseInt(a.slice(0, -1));
        const seatB = parseInt(b.slice(0, -1));
        return seatA - seatB;
      });
    },
    sortedSeats(val) {
      const sortedSeats = {};

      val.sort((a, b) => {
        const rowA = a.slice(0, -1);
        const rowB = b.slice(0, -1);
        const seatA = parseInt(a.slice(-1));
        const seatB = parseInt(b.slice(-1));

        if (rowA === rowB) {
          return seatA - seatB;
        }
        return rowA.localeCompare(rowB);
      }).forEach(seat => {
        const row = seat.slice(-1);
        if (!sortedSeats[row]) {
          sortedSeats[row] = [];
        }
        sortedSeats[row].push(seat);
      });

      return Object.values(sortedSeats);
    },

    selectSeat(seat) {
      console.log("hi");
      const index = this.selectedSeat.indexOf(seat);
      if (index === -1) {
        //Seat is not in the array, so add it
        this.selectedSeat.push(seat);
        console.log("donnée insérée")
        console.log("selectedseat", this.selectedSeat)
      } else {
        // Seat is already in the array, so remove it
        this.selectedSeat.splice(index, 1);
        console.log("donnée retirée")
        console.log("selected removed", this.selectedSeat)
      }

    },
    nextStep() {
      if (this.currentStep == 1) {
        if (this.buy.lineId !== null && this.buy.lineId !== undefined) {
          if (this.lineIdSelected.length == 0) {
            this.$toast.error("Choisissez une ligne !")
          } else if (this.selectedUser.length == 0) {
            this.$toast.error("Recherchez un utilisateur !")
            return
          } else if (this.buy.date == null || this.buy.date == "") {
            this.$toast.error("La date de départ est requise !")
            return

          } else if (this.buy.horraire === null || this.buy.horraire == "") {
            this.$toast.error("L'heure de départ est requise !")
            return

          } else {
            this.currentStep++
            return
          }
          return


        } else if (this.selectedUser.length == 0) {
          this.$toast.error("Recherchez un utilisateur !")
          return
        } else {
          this.$toast.error("Choisissez une ligne !")
          return
        }
      } else {
        this.currentStep++
      }
    },
    async handleClick() {
      await this.checkSeat();
      this.nextStep();

    },

    showDataCons(val) {
      const index = this.time.indexOf(val);
      if (index === -1) {
        this.time.push(val);
        this.buy.horraire = this.time[0];
        console.log("time in if true", this.time[0]);
        console.log("buy time", this.buy.horraire);
      } else {
        this.time.splice(index, 1);
        if (this.time.length === 0) {
          this.buy.horraire = "";
        } else {
          this.buy.horraire = this.time[0];
        }
        console.log("time in else", this.time[0]);
        console.log("buy time", this.buy.horraire);
        console.log("donnée retirée");
      }
    },

    async checkSeat() {
      console.log("dis.buy", this.buy)

      const response = await apiRequest('POST', 'hors-trajet-verif-siege', {
        lineId: this.buy.lineId,
        nbrePlace: this.buy.seat,
        heureDepart: this.buy.horraire,
        dateDepart: this.buy.date,
        list: this.seatList,
      });

      if (response) {
        this.seatListTwo = response.data
        //console.log("response", this.seatListTwo )
      }

    },
    selectTarget(val) {
      if (this.selectedUser[0] == val.id) {
        this.selectedUser = [];
        this.buy.user = null
        console.log("val", this.buy.user)
        return
      } else {
        this.selectedUser = [];
        this.selectedUser.push(val.id);
        this.buy.user = this.selectedUser[0]
        console.log("val", this.buy.user)
      }
    },
    async userSearch() {
      //this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/user-recherche",

        { mots: this.recherche },
        false
      );
      if (dataSearch && dataSearch.data) {
        this.paginatedDate = [];
        console.log(dataSearch.data);
        const formattedTable = dataSearch.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          return {
            id: user.id,
            username: nom + " " + prenom,
            firstname: user.firstname != null ? user.firstname : "",
            lastname: user.lastname != null ? user.lastname : "",
            pays: user.pays != null ? user.pays : "",
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            email: user.email != null ? user.email : "",
            //trajet: user.nombreTrajet != null ? user.nombreTrajet : "",
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            badge: user.userValidated != null ? user.userValidated : "",
            //type: type,
            statut: user.active ? "Actif" : "Suspendu",
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.userFound = formattedTable;
        console.log(this.userFound);
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;

        this.userChargeLoader = false;
      }
    },


    async init() {
      //Chargement des données
      const data = await apiRequest("GET", "commission-list", undefined, false);
      if (data && data.data) {
        console.log("liste des compagnue -commission:", data.data);
        const filtered = data.data.sort((a, b) => b.id - a.id);
        const formattedTable = filtered.map((agence) => {
          var id = agence.id;
          var designation = agence.personne.lastname + " " + agence.personne.firstname;
          var email = agence.personne.email;
          var commission = agence.commission.montant
          //var ifu= agence.ifu;
          //var responsable=agence.responsable;
          var tel = agence.personne.indicatifTel + " " + agence.personne.tel
          //var ville=agence.ville;
          //var nbreVehicule=agence.nbreVehicule;
          return {
            id: id,
            designation: designation,
            email: email,
            //ifu: ifu,
            //nbreVehicule:nbreVehicule,
            //responsable: responsable,
            commission: commission || "non défini",
            tel: tel,
            //ville: ville,
          };
        });
        this.ActifData = formattedTable;
        this.totalActif = this.ActifData.length
      }
    },
    storeSeat(val) {


      this.selectedSeat.push(val)
    },
    selectLine(item) {

      console.log("ligne choise icinn", item)

      if (this.lineIdSelected[0] == item.id) {
        this.lineIdSelected = []
        console.log("item.horraire", item.horraire)
        this.buy.lineId = this.lineIdSelected[0];
        console.log(" this.buy.lineId", this.buy.lineId);

        // Assuming item.horraire is your array of arrays



      } else {
        this.selectedTime = []
        this.lineIdSelected = []
        this.lineIdSelected.push(item.id);
        console.log("item.horraire nn", item.horraire)
        for (let el of item.horraire) {

          console.log("in for", el)
          // Check if the sub-array has elements
          if (el.length > 0) {
            // Push the first element of the sub-array into selectedSeat
            this.selectedTime.push(el[0]);
          }
        }

        console.log("sieges séléctionnés", this.selectedTime)

        this.buy.lineId = this.lineIdSelected[0];
        console.log(" this.buy.lineId", this.buy.lineId);

      }

    },

    async buyTicketModal(val) {
      console.log("val", val)
      this.time = []
      this.selectedSeat = [];
      this.seatListTwo = [];
      this.userFound = [];
      this.selectedUser = [];

      this.selectedTime = [];
      this.lineIdSelected = [];
      this.currentStep = 1;
      this.buy.compagnieId = val.id

      //await this.showCoLine(val.id)
      const linereq = await apiRequest("GET", `compagnies/lines?compagnieId=${val.id}`)
      if (linereq.status == 200) {

        const reponseData = linereq.data

        this.showLine = reponseData;


        console.log("hoyé", linereq.data)

      }

      this.buyModal = true



    },

    async showCoLine(coId) {
      const response = await apiRequest("GET", `compagnies/lines?compagnieId=${coId}`)
      if (response.status == 200) {

        const reponseData = response.data

        this.showLine = reponseData;
        this.showModal = true;

        console.log("hoyé", response.data)

      }

    }
    ,
    async showSold(co) {
      console.log('co', co);
      const response = await apiRequest("GET", `dash/all-ticket-per-line?compagnieId=${co.id}`);

      if (response.status == 200) {
        console.log("before filtered", response.data);
        const filtered = await response.data.filter(data => (data.user) && (data.statut == "ACTIF"));
        this.soldData = filtered;
        console.log('soldData', this.soldData)
        this.soldModal = true;

      } else {
        this.$toast.error("Une erreur est survenue !")
      }

      console.log("response", this.soldData);


    },
    async appCo() {
      //Chargement des données
      const data = await apiRequest("GET", "get-all-companies", undefined, false);
      if (data && data.data) {
        console.log("approvedCO:", data.data);
        const filtered = data.data.sort((a, b) => b.id - a.id);
        const formattedTable = filtered.map((agence) => {
          var id = agence.id;
          var designation = agence.lastname + " " + agence.firstname;
          var email = agence.email;
          //var ifu= agence.ifu;
          //var responsable=agence.responsable;
          var tel = agence.indicatifTel + " " + agence.tel
          var commission = agence.commission.montant
          //var ville=agence.ville;
          //var nbreVehicule=agence.nbreVehicule;
          return {
            id: id,
            designation: designation,
            email: email,
            //ifu: ifu,
            commission: commission || "non défini",
            //responsable: responsable,
            tel: tel,
            //ville: ville,
          };
        });
        this.approvedCo = formattedTable;
        this.totalActif = this.ActifData.length
      }
    },
    async updateCommissiion() {

      console.log("values", this.idSociety, "montant", this.commission)
      const data = await apiRequest("POST",
        "add-com",
        {
          idSociety: this.idSociety,
          montant: this.commission,
        })
      if (data.status == 200) {
        //console.log("success",data)
        this.$toast.success("Commission modifiée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      } else {
        this.$toast.error("Un problème est survenu", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
      this.commissionModal = false
      this.init();
      this.approvedCo();
    },
    showCommission(row) {
      this.commission = row.commission,
        this.idSociety = row.id,
        this.commissionModal = true
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async enableCo(user) {
      console.log("enable this user", user);
      const req = await apiRequest("PUT", "user-as-company", { user: user, active: true });
      console.log("enable this user", req);
      if (req.status == 200) {
        this.$toast.success("Status compagnie attribué avec succès")
        this.init();
        this.approvedCo();
      } else {
        this.$toast.error("Un problème est survenu", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        })
        this.init();
        this.approvedCo();
      }
    },
    async disableCo(user) {
      const req = await apiRequest("PUT", "user-as-company", { user: user, active: false })
      if (req.status == 200) {
        this.$toast.success("Status compagnie retiré avec succès")
        this.init();
        this.approvedCo();
      } else {
        this.$toast.error("Un problème est survenu", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        })
        this.init();
        this.approvedCo();
      }
    },

    newLineOpener(coId) {
      this.errorForm = "";
      this.showLineModal = true;
      this.line.horraire = [];
      this.coId = coId;
    },


    editLineOpener(line) {

      console.log("line", line);
      //this.line = line;
      this.line.lieuDepart.nom = line.lieuDepart;
      this.line.lieuDepart.latitude = line.latitudeDepart;
      this.line.lieuDepart.longitude = line.longitudeDepart;
      this.line.lieuArrive.nom = line.lieuArrive;
      this.line.lieuArrive.latitude = line.latitudeArrivee;
      this.line.lieuArrive.longitude = line.longitudeArrivee;
      this.line.lieuEmbarquement = line.lieuEmbarquement;
      this.line.lieuDebarquement = line.lieuDebarquement;
      this.line.prix = line.prix;
      this.line.horraire = line.horraire;
      this.line.priseElectric = line.priseElectric;
      this.line.nombrePlace = line.nombrePlace;
      this.line.wifi = line.wifi;
      this.line.assistancePMR = line.assistancePMR;
      this.line.assurance = line.assurance;
      this.line.siegeClinable = line.siegeClinable;
      this.line.tailleBagage = line.tailleBagage;
      this.line.toilet = line.toilet;
      this.line.daysOfWeek = line.daysOfWeek;
      this.line.isPromo = line.isPromo;


      console.log("this edit line", this.line)
      this.lineId = line.id;

      console.log("this.lineid", this.lineId)
      this.editLineModal = true;
    },
    async editLine() {
      if (this.validateForm() == false) {
        await this.$toast.error(this.errorForm)
        return false
      } else {
        console.log("In update");

        const req = await apiRequest("PUT", "update-bus-line", {
          lineId: this.lineId,

          lieuDepart: this.line.lieuDepart,
          lieuArrive: this.line.lieuArrive,
          lieuEmbarquement: this.line.lieuEmbarquement,
          lieuDebarquement: this.line.lieuDebarquement,
          prix: this.line.prix,
          horraire: this.line.horraire,
          daysOfWeek: this.line.daysOfWeek,
          isPromo: this.line.isPromo,
          nombrePlace: this.line.nombrePlace,
          wifi: this.line.wifi,
          toilet: this.line.toilet,
          siegeClinable: this.line.siegeClinable,
          priseElectric: this.line.priseElectric,
          assurance: this.line.assurance,
          tailleBagage: this.line.tailleBagage, //isIn:   ["GRAND","MOYEN","PETIT"]},
          assistancePMR: this.line.assistancePMR,

        })
        if (req.status == 200) {
          this.$toast.success("Ligne Modifiée avec succès")
          this.editLineModal = false;
        } else {
          //console.log("error");
          this.$toast.error("Une erreur est survenue")
          // this.showLineModal = false;
          // this.init();
          // this.approvedCo();
        }
      }




    },
    async affectId(val) {

      console.log("val", val)
      this.lineId = val.id
    },
    async disableLine(val) {
      await this.affectId(val)
      const req = await apiRequest("PUT", "update-bus-line", { lineId: this.lineId, active: false })
      if (req.status == 200) {

        this.$toast.success("Ligne désactivée avec succès")
        this.showCoLine(val.compagnieId.id)

      } else {
        this.$toast.error("Une erreur est survenue")
      }


    },
    async enableLine(val) {
      await this.affectId(val)
      const req = await apiRequest("PUT", "update-bus-line", {
        lineId: this.lineId,
        active: true
      })
      if (req.status == 200) {

        this.$toast.success("Ligne activée avec succès")
        this.showCoLine(val.compagnieId.id)

      } else {
        this.$toast.error("Une erreur est survenue")
      }


    },
    async newLine(coId) {
      if (coId) {
        this.line.compagnieId = coId;
        console.log("the line", this.line);
        if (this.validateForm() == true) {
          const req = await apiRequest("POST", "add-bus-line", this.line)
          if (req.status == 200) {
            this.$toast.success("Nouvelle ligne ajoutée avec succès")
            this.showLineModal = false;
          } else {
            //console.log("error");
            this.$toast.error("Une erreur est survenue")
            // this.showLineModal = false;
            // this.init();
            // this.approvedCo();
          }
        } else {
          this.$toast.error(this.errorForm)
          console.log("error")
          // this.showLineModal = false;
          // this.init();
          // this.approvedCo();
        }
      }
    },

    validateForm() {
      this.errorForm = "";
      // Check for validation of each field
      if (!this.line.lieuDepart.nom) {
        // If nom is empty, show error message and return false
        console.log("rrr", this.line.lieuDepart.nom)
        this.errorForm = "Départ de la ligne est requis";
        return false;
      }
      if (!this.line.lieuArrive.nom) {
        // If nom is empty, show error message and return false
        this.errorForm = "Arrivé de la ligne est requis";
        return false;
      }
      if (!this.line.lieuEmbarquement) {
        // If nom is empty, show error message and return false
        this.errorForm = "Lieu d'embarquement est requis";
        return false;
      }
      if (!this.line.lieuDebarquement) {
        // If nom is empty, show error message and return false
        this.errorForm = "Lieu de débarquement est requis";
        return false;
      }

      if (!this.line.nombrePlace) {
        // If nom is empty, show error message and return false
        this.errorForm = "Nombre de place requis";
        return false;
      }

      if (!this.line.prix || this.line.prix == 0) {
        // If nom is empty, show error message and return false
        this.errorForm = "Le prix est requis";
        return false;
      }

      if (!this.line.tailleBagage) {
        // If price is empty, show error message and return false
        this.errorForm = "La taille de bagage est requise !";
        return false;
      }

      if (this.line.horraire.length < 1 || this.line.horraire.length === 0) {
        console.log(this.line.horraire.length)
        // If nom is empty, show error message and return false
        this.errorForm = "Les horraires départ : arrivé sont requis";
        return false;
      }
      if (this.line.daysOfWeek.length < 1 || this.line.daysOfWeek.length === 0) {
        console.log(this.line.daysOfWeek.length)
        // If nom is empty, show error message and return false
        this.errorForm = "Les jours de disponibilité son requis";
        return false;
      }
      if (this.line.isPromo == null || this.line.isPromo == undefined) {
        console.log("Promo state in error", this.line.isPromo)
        // If nom is empty, show error message and return false
        this.errorForm = "Spécifiez si le statut de la promotion";
        return false;
      }

      if (this.errorForm == "") {
        return true
      } else {
        return false
      }

    },
    setLineTime(dep, arr) {
      if (!(dep && arr)) {
        this.$toast.error(" Heure de départ et d'arrivée requis")
      } else {
        this.line.horraire.push([dep, arr])
      }
    },
    setDaysOfWeek(day) {
      if (!day) {
        this.$toast.error(" Choisissez les jours pour cette ligne ")
      } else {
        if (!this.line.daysOfWeek.includes(day)) {
          this.line.daysOfWeek.push(day)
        } else {
          const itemToRemove = day
          const ind = this.line.daysOfWeek.indexOf(itemToRemove)
          if (ind !== -1) {
            this.line.daysOfWeek.splice(ind, 1)
          }

        }
      }
      console.log("days selected", this.line.daysOfWeek)
    },
    resetTime() {
      this.line.horraire = []
    },
    setLineStartPoint: function (place) {
      if (place) {
        this.line.lieuDepart.nom = place.formatted_address;
        this.line.lieuDepart.longitude = place.geometry.location.lng();
        this.line.lieuDepart.latitude = place.geometry.location.lat();
      }
    },
    setLineEndPoint: function (place) {
      if (place) {
        this.line.lieuArrive.nom = place.formatted_address;
        this.line.lieuArrive.longitude = place.geometry.location.lng();
        this.line.lieuArrive.latitude = place.geometry.location.lat();
      }
    },
    confirmationModalOpener(ticket) {
      this.billetId = ticket.id

      this.confirmationModal = true

    },
    async cancelTicket(ticket) {
      this.confirmationModal = false
      console.log("bill-", ticket)

      const response = await apiRequest('POST', 'annuler-ticket', { billetId: this.billetId });
      if (response && response.status == 200) {

        this.$toast.success("Billet annulé avec succès")
        this.soldModal = false


        console.log(response.data)

      } else {
        this.$toast.success("Un problème est survenu")
      }

    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Utilisateurs avec le statut de compagnie</a>
                </template>
                <div class="row  d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter" />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table style="cursor: pointer" class="table-centered" :items="ActifData" :fields="fields"
                    responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                    @filtered="onFiltered">
                    <template v-slot:cell(badge)="row">
                      <div class="py-2 d-flex justify-content-center" :class="{
      'fa fa-star': `${row.value}` === 'false',
      'fa fa-star badge-soft-warning':
        `${row.value}` === 'true',
    }"></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div class="py-2 d-flex justify-content-center badge font-size-12" :class="{
      'badge-soft-danger': `${row.value}` === 'Suspendu',
      'badge-soft-success': `${row.value}` === 'Actif',
    }">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover
                        @click="showCommission(row.item)" title="Éditer">
                        <i class="mdi mdi-pencil font-size-18" style="cursor: pointer;"></i>
                      </a>
                      &nbsp;
                      <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover
                        @click="enableCo(row.item.id)" title="Activer">
                        <i class="mdi mdi-check font-size-18 text-success"></i>
                      </a>
                      &nbsp;
                      <a href="javascript:void(0);" class="text-primary" @click="disableCo(row.item.id)">
                        <i class="mdi mdi-cancel font-size-18 text-danger" v-b-tooltip.hover title="Désactiver"></i>
                      </a>
                    </template>
                    <template v-slot:cell(tel)="row">
                      <div style="text-align: center">
                        <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                          title="Envoyer un message whatsapp" target="_blank">
                          {{ row.value }}
                        </a>
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="totalActif" :per-page="perPage"
                          :rows="ActifData"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Compagnies actives</a>
                </template>
                <div class="row  d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter" />
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div>
                  <button @click="generatePDF" class='btn btn-primary'>
                    Générer
                  </button>

                  <div id="pdfTicket">
                    <div class="container p-4 mb-4 text-center">
                      <div ref="divToConvert" id="divToConvert" v-if='billData' class="bg-white text-dark mx-auto"
                        style="max-width: 800px;">
                        <header class="text-center mb-4">
                          <h3 class="font-weight-bold">FACTURE N° #<span>{{ billData.id + "-" + billData.lineId.id + "-"
                                  +
                                  billData.user.id }}</span></h3>
                        </header>
                        <div class="row mb-4 align-items-center">
                          <div class="col-6 d-flex justify-content-start ">
                            <img :src="billData.lineId.compagnieId.logo" alt="Logo" title="Logo" class="img-fluid"
                              style="max-height: 56px;">
                          </div>
                          <div class="col-6 text-right">
                            <div class="font-weight-bold">{{ billData.lineId.compagnieId.fullname }}</div>
                            <div>{{ billData.lineId.compagnieId.indicatifTel + billData.lineId.compagnieId.tel }}</div>
                            <div>{{ billData.lineId.compagnieId.email }}</div>
                          </div>
                        </div>
                        <hr class="border-dark">
                        <div class="row mt-2">
                          <div class="col-6 text-center">
                            <div><span class="font-weight-bold">Date:</span> {{ new
      Date(billData.dateDepart).toLocaleDateString("FR-fr") }}</div>
                          </div>
                          <div class="col-6 text-center">
                            <div><span class="font-weight-bold">Heures:</span> {{ billData.heureDepart }}</div>
                          </div>
                        </div>
                        <div class="row mt-4 justify-content-around">
                          <div class="col-md-5 border border-success rounded p-2 ml-2 mb-2">
                            <span class="font-weight-bold d-block">Départ: {{ billData.lineId.lieuDepart }}</span>
                            <span class="font-weight-bold d-block">Point de rencontre: {{
      billData.lineId.lieuEmbarquement }}</span>
                          </div>
                          <div class="col-md-5 border border-success rounded p-2 mr-2 mb-2">
                            <span class="font-weight-bold d-block">Arrivée : {{ billData.lineId.lieuArrive }}</span>
                            <span class="font-weight-bold d-block">Point de descente : {{
      billData.lineId.lieuDebarquement }}</span>
                          </div>
                        </div>
                        <table class="table table-bordered mt-4">
                          <thead class="bg-light">
                            <tr>
                              <th>Description</th>
                              <th class="text-center">Quantité</th>
                              <th class="text-center">Prix unitaire</th>
                              <th class="text-center">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Trajet</td>
                              <td class="text-center">{{ billData.nbreTotal }}</td>
                              <td class="text-center">{{ billData.lineId.prixBrute }} fcfa</td>
                              <td class="text-center">{{ billData.lineId.prixBrute * billData.nbreTotal }} fcfa</td>
                            </tr>
                            <tr>
                              <td>Assurance</td>
                              <td class="text-center">{{ billData.nbrePlace }}</td>
                              <td class="text-center">_</td>
                              <td class="text-center">{{ billData.assurance }} fcfa</td>
                            </tr>
                            <tr>
                              <td>Commission</td>
                              <td class="text-center">_</td>
                              <td class="text-center">_</td>
                              <td class="text-center">{{ billData.commission }} fcfa</td>
                            </tr>
                          </tbody>
                        </table>
                        <hr class="border-dark">
                        <div class="row">
                          <div class="col-md-6">
                            <img :src="billData.qrcode" alt="QR Code" class="img-fluid">
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-6 font-weight-bold text-left">
                                <div>Total HT</div>
                                <div>TVA sur com. (18%)</div>
                                <div>Total TTC</div>
                              </div>
                              <div class="col-6 text-right">
                                <div>{{ (billData.lineId.prixBrute * billData.nbreTotal) + (billData.assurance) +
      (billData.commission) }} fcfa</div>
                                <div>0 fcfa</div>
                                <div>{{ (billData.lineId.prixBrute * billData.nbreTotal) + (billData.assurance) +
      (billData.commission) }} fcfa</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-center mt-5 pt-5">
                          <hr class="border-dark">
                          <hr class="border-dark mt-1">
                          <div class="font-weight-bold">RMOBILITY</div>
                          <div class="font-weight-bold">RAINIS | RCCM RB/ABC</div>
                          <div class="font-weight-bold">Adresse: Immeuble de la Pharmacie ZOGBO (COTONOU,BENIN)</div>
                          <div class="font-weight-bold">Téléphone:+229 513 909 71</div>
                          <div class="font-weight-bold">Email: info@raynis.co</div>
                        </div>
                      </div>
                      <!-- <div class="row mt-4" :class="{'d-none':!billData}">
    <div class="col-md-4">
      <button type="button" @click="sendPdf" class="btn btn-outline-secondary w-100">Fermer</button>
    </div>
    <div class="col-md-4">
      <button type="button" class="btn btn-danger w-100" v-print="printObj">Imprimer</button>
    </div>
    <div class="col-md-4">
      <button type="button" class="btn btn-danger w-100" @click="sendPdf">Envoyer</button>
    </div>
  </div> -->
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <b-table style="cursor: pointer" class="table-centered" :items="approvedCo" :fields="fieldsCo"
                    responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                    @filtered="onFiltered">
                    <template v-slot:cell(badge)="row">
                      <div class="py-2 d-flex justify-content-center" :class="{
      'fa fa-star': `${row.value}` === 'false',
      'fa fa-star badge-soft-warning':
        `${row.value}` === 'true',
    }"></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div class="py-2 d-flex justify-content-center badge font-size-12" :class="{
      'badge-soft-danger': `${row.value}` === 'Suspendu',
      'badge-soft-success': `${row.value}` === 'Actif',
    }">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(ligne)="row">
                      <a href="javascript:void(0);" class="text-primary " style="cursor: pointer;"
                        @click="showCoLine(row.item.id)" title="Voir" v-b-tooltip.hover>
                        <i class="mdi mdi-eye font-size-18"></i>
                      </a>
                      &nbsp;
                    </template>
                    <template #cell(sold)="row">
                      <div class="d-flex justify-content-center">
                        <i class="fas fa-eye text-primary" style="cursor: pointer;" @click="showSold(row.item)"></i>
                      </div>
                    </template>
                    <template #cell(buy)="row">
                      <div class=" d-flex justify-content-center">
                        <button class="btn btn-success d-inline" @click="buyTicketModal(row.item)"> <i d-inline
                            class="mdi mdi-basket" style="cursor: pointer; color:white"></i></button>
                        &nbsp;
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover
                        @click="showCommission(row.item)" title="Éditer commission">
                        <i class="mdi mdi-pencil font-size-18" style="cursor: pointer;"></i>
                      </a>
                      &nbsp;
                      <a id="addline" href="javascript:void(0);" class="text-primary"
                        @click="newLineOpener(row.item.id)" style="cursor: pointer;" title="Ajouter" v-b-tooltip.hover>
                        <i class="mdi mdi-plus font-size-18"></i>

                      </a>

                      &nbsp;
                    </template>
                    <template v-slot:cell(tel)="row">
                      <div style="text-align: center">
                        <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                          title="Envoyer un message whatsapp" target="_blank">
                          {{ row.value }}
                        </a>
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="totalActif" :per-page="perPage"
                          :rows="ActifData"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- @hidden="closeModal" -->
    <b-modal id="modal-center" centered size="lg" v-model="showLineModal" title="Ajouter une ligne"
      title-class="font-18" hide-footer>
      <form @submit.prevent="newLine(coId)" class="p-3">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="depart">Départ de la ligne</label>
              <gmap-autocomplete :value="line.lieuDepart.nom" type="text" class="form-control" id="depart"
                placeholder="Point de départ" @place_changed="setLineStartPoint" :select-first-on-enter="true"
                style="height: 50px" @keydown.enter.prevent="">
              </gmap-autocomplete>
              <!-- <small class="text-danger" v-if="!line.lieuDepart.nom">Départ de la ligne est requis</small> -->
            </div>
            <div class="form-group">
              <label for="arri">Arrivé de la ligne</label>
              <gmap-autocomplete :value="line.lieuArrive.nom" type="text" class="form-control" id="arri"
                placeholder="Point d'arrivé" @place_changed="setLineEndPoint" :select-first-on-enter="true"
                style="height: 50px" @keydown.enter.prevent="">
              </gmap-autocomplete>
            </div>
            <div class="form-group">
              <label for="place">Lieu embarquement</label>
              <input id="place" class="form-control" placeholder="Lieu embarquement" type="text"
                v-model="line.lieuEmbarquement" style="height: 50px">
            </div>
            <div class="form-group">
              <label for="place">Lieu débarquement</label>
              <input id="place" class="form-control" placeholder="Lieu débarquement" type="text"
                v-model="line.lieuDebarquement" style="height: 50px">
            </div>
            <div class="form-group ">
              <label for="time" class="form-label">Les jours de la semaine</label>
              <div class="row d-flex  justify-content-md-between  align-items-md-center p-2 no-whitespace no-wrap">

                <div v-for="(day, index) in days" :key=index>
                  <span @click="setDaysOfWeek(day.value)" class="day p-2 rounded-circle border-secondary shadow-sm"
                    :class="{ 'bg-color-custom': line.daysOfWeek.includes(day.value) }">

                    {{ day.name }}

                  </span>

                </div>


              </div>

              <div class="row p-2" v-if="line.daysOfWeek.length > 0">
                <label for="" class="form-label mt-3">Jours choisis</label>

                <div v-for="(el, index) in line.daysOfWeek" :key="index">
                  <span v-for="(day, index) in days" :key="index">

                    <span v-if="day.value == el" :class="{ 'col- btn btn-primary text-white m-2 p-2': day.value == el }"
                      @click="setDaysOfWeek(day.value)">

                      {{ day.value }}

                    </span>
                  </span>
                </div>

              </div>

              <div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="place">Place disponible</label>
              <input id="place" class="form-control" placeholder="Place disponible" type="number"
                v-model="line.nombrePlace" style="height: 50px">
            </div>
            <div class="form-group">
              <label for="pack" class="form-label">Taille de bagage autorisée</label>
              <select name="" id="pack" v-model="line.tailleBagage" class="form-control" style="height: 50px">
                <option value="GRAND">GRANDE</option>
                <option value="MOYEN">MOYENNE</option>
                <option value="PETIT">PETITE</option>
              </select>
              <div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label for="place">Prix</label>
                  <input id="place" class="form-control" placeholder="Place disponible" type="number"
                    v-model="line.prix" style="height: 50px">
                </div>
                <div class="col-md-6">
                  <label for="pricetype" class="form-label">Promotion</label>
                  <select v-model="line.isPromo" class="form-control" id="pricetype" style="height: 50px">
                    <option :value="false">Non</option>
                    <option :value="true">Oui </option>
                  </select>

                </div>
              </div>

            </div>

            <div class="form-group ">
              <label for="time">Horraires de la ligne</label>
              <div class="row d-flex">
                <div class="col-md-4"><label for="dep">Départ</label> <input type="time" v-model="depTime" id="dep"
                    class="form-control" style="height: 50px"></div>
                <div class="col-md-4"><label for="arr">Arrivé</label><input type="time" v-model="arrTime" id="arr"
                    class="form-control" style="height: 50px"></div>
                <div class="col- d-flex"> <button href="btn btn-primary" class="btn btn-primary mt-4  mr-2"
                    @click.prevent="setLineTime(depTime, arrTime)" style="margin:auto;height: 50px"><i
                      class="fas fa-check"></i></button>
                  <button href="btn btn-primary" class="btn btn-primary mt-4" @click.prevent="resetTime()"
                    style="margin:auto;height: 50px">Vider</button>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="form-group">
              <label class="d-block">
                Horraires ajoutés
              </label>
              <div v-for="t in line.horraire" :key="t">
                {{ t }}
              </div>
            </div>
            <!-- <div class="form-group">
                                      <label for="place">Pays</label>
                                      <input id="place" class="form-control" type="number" v-model="line.country">
                                    </div> -->
            <!-- <div class="form-group">
                                      <label for="smokeInCar">Heure Départ</label>
                                      <input class="form-control" type="text" v-model="line.startHour">
                                    </div>
                                    <div class="form-group">
                                      <label for="smokeInCar">Heure d'arrivée</label>
                                      <input class="form-control" type="text" v-model="line.endHour">
                                    </div> -->
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-3 form-group d-flex ">
            <label clas for="wifi" class="form-label">Wifi</label>
            <input class="form-control" type="checkbox" v-model="line.wifi" id="wifi">
          </div>
          <div class="col-md-3 form-group d-flex">
            <label for="wifi">Prise Électrique</label>
            <input class="form-control" type="checkbox" v-model="line.priseElectric" id="">
          </div>
          <div class="col-md-3 form-group d-flex">
            <label for="wifi">Toilet</label>
            <input class="form-control" type="checkbox" v-model="line.toilet" id="">
          </div>
          <div class="col-md-3 form-group d-flex">
            <label for="wifi">Siege Clinable</label>
            <input class="form-control" type="checkbox" v-model="line.siegeClinable" id="">
          </div>
          <div class="col-md-3 form-group d-flex">
            <label for="wifi">Assurance</label>
            <input class="form-control" type="checkbox" v-model="line.assurance" id="">
          </div>
          <div class="col-md-3 form-group d-flex">
            <label for="wifi">Assistance PMR</label>
            <input class="form-control" type="checkbox" v-model="line.assistancePMR" id="">
          </div>


        </div>

        <div class="row d-flex">
          <small class="text-danger" v-if="errorForm">{{ errorForm }}</small>
        </div>

        <div class="row d-flex justify-content-end">


          <div class="col- mr-2"><b-button variant="secondary" @click="showLineModal = false">Fermer</b-button></div>
          <div class="col- ">
            <b-button type="submit" variant="primary">
              Ajouter

            </b-button>
          </div>
        </div>

      </form>

    </b-modal>

    <b-modal id="modal-center" centered size="lg" v-model="editLineModal" title="Modifier la  ligne"
      title-class="font-18" hide-footer>
      <form @submit.prevent="editLine()" class="p-3">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="depart">Départ de la ligne</label>
              <gmap-autocomplete :value="line.lieuDepart.nom" type="text" class="form-control" id="depart"
                placeholder="Point de départ" @place_changed="setLineStartPoint" :select-first-on-enter="true"
                style="height: 50px" @keydown.enter.prevent="">
              </gmap-autocomplete>
              <!-- <small class="text-danger" v-if="!line.lieuDepart.nom">Départ de la ligne est requis</small> -->
            </div>
            <div class="form-group">
              <label for="arri">Arrivé de la ligne</label>
              <gmap-autocomplete :value="line.lieuArrive.nom" type="text" class="form-control" id="arri"
                placeholder="Point d'arrivé" @place_changed="setLineEndPoint" :select-first-on-enter="true"
                style="height: 50px" @keydown.enter.prevent="">
              </gmap-autocomplete>
            </div>
            <div class="form-group">
              <label for="place">Lieu embarquement</label>
              <input id="place" class="form-control" placeholder="Lieu embarquement" type="text"
                v-model="line.lieuEmbarquement" style="height: 50px">
            </div>
            <div class="form-group">
              <label for="place">Lieu débarquement</label>
              <input id="place" class="form-control" placeholder="Lieu débarquement" type="text"
                v-model="line.lieuDebarquement" style="height: 50px">
            </div>
            <div class="form-group ">
              <label for="time" class="form-label">Les jours de la semaine</label>
              <div class="row d-flex  justify-content-md-between  align-items-md-center p-2 no-whitespace no-wrap">

                <div v-for="(day, index) in days" :key=index>
                  <span @click="setDaysOfWeek(day.value)" class="day p-2 rounded-circle border-secondary shadow-sm"
                    :class="{ 'bg-color-custom': line.daysOfWeek.includes(day.value) }">

                    {{ day.name }}

                  </span>

                </div>


              </div>

              <div class="row p-2" v-if="line.daysOfWeek.length > 0">
                <label for="" class="form-label mt-3">Jours choisis</label>

                <div v-for="(el, index) in line.daysOfWeek" :key="index">
                  <span v-for="(day, index) in days" :key="index">

                    <span v-if="day.value == el" :class="{ 'col- btn btn-primary text-white m-2 p-2': day.value == el }"
                      @click="setDaysOfWeek(day.value)">

                      {{ day.value }}

                    </span>
                  </span>
                </div>

              </div>

              <div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="place">Place disponible</label>
              <input id="place" class="form-control" placeholder="Place disponible" type="number"
                v-model="line.nombrePlace" style="height: 50px">
            </div>
            <div class="form-group">
              <label for="pack" class="form-label">Taille de bagage autorisée</label>
              <select name="" id="pack" v-model="line.tailleBagage" class="form-control" style="height: 50px">
                <option value="GRAND">GRANDE</option>
                <option value="MOYEN">MOYENNE</option>
                <option value="PETIT">PETITE</option>
              </select>
              <div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label for="place">Prix</label>
                  <input id="place" class="form-control" placeholder="Place disponible" type="number"
                    v-model="line.prix" style="height: 50px">
                </div>
                <div class="col-md-6">
                  <label for="pricetype" class="form-label">Promotion</label>
                  <select v-model="line.isPromo" class="form-control" id="pricetype" style="height: 50px">
                    <option :value="false">Non</option>
                    <option :value="true">Oui</option>

                  </select>

                </div>
              </div>

            </div>
            <div class="form-group ">
              <label for="time">Horraires de la ligne</label>
              <div class="row d-flex">
                <div class="col-md-4"><label for="dep">Départ</label> <input type="time" v-model="depTime" id="dep"
                    class="form-control" style="height: 50px"></div>
                <div class="col-md-4"><label for="arr">Arrivé</label><input type="time" v-model="arrTime" id="arr"
                    class="form-control" style="height: 50px"></div>
                <div class="col- d-flex"> <button href="btn btn-primary" class="btn btn-primary mt-4  mr-2"
                    @click.prevent="setLineTime(depTime, arrTime)" style="margin:auto;height: 50px"><i
                      class="fas fa-check"></i></button>
                  <button href="btn btn-primary" class="btn btn-primary mt-4" @click.prevent="resetTime()"
                    style="margin:auto;height: 50px">Vider</button>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="form-group">
              <label class="d-block">
                Horraires ajoutés
              </label>
              <div v-for="t in line.horraire" :key="t">
                {{ t }}
              </div>
            </div>
            <!-- <div class="form-group">
                                      <label for="place">Pays</label>
                                      <input id="place" class="form-control" type="number" v-model="line.country">
                                    </div> -->
            <!-- <div class="form-group">
                                      <label for="smokeInCar">Heure Départ</label>
                                      <input class="form-control" type="text" v-model="line.startHour">
                                    </div>
                                    <div class="form-group">
                                      <label for="smokeInCar">Heure d'arrivée</label>
                                      <input class="form-control" type="text" v-model="line.endHour">
                                    </div> -->
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-3 form-group d-flex ">
            <label clas for="wifi" class="form-label">Wifi</label>
            <input class="form-control" type="checkbox" v-model="line.wifi" id="wifi">
          </div>
          <div class="col-md-3 form-group d-flex">
            <label for="wifi">Prise Électrique</label>
            <input class="form-control" type="checkbox" v-model="line.priseElectric" id="">
          </div>
          <div class="col-md-3 form-group d-flex">
            <label for="wifi">Toilet</label>
            <input class="form-control" type="checkbox" v-model="line.toilet" id="">
          </div>
          <div class="col-md-3 form-group d-flex">
            <label for="wifi">Siege Clinable</label>
            <input class="form-control" type="checkbox" v-model="line.siegeClinable" id="">
          </div>
          <div class="col-md-3 form-group d-flex">
            <label for="wifi">Assurance</label>
            <input class="form-control" type="checkbox" v-model="line.assurance" id="">
          </div>
          <div class="col-md-3 form-group d-flex">
            <label for="wifi">Assistance PMR</label>
            <input class="form-control" type="checkbox" v-model="line.assistancePMR" id="">
          </div>


        </div>

        <div class="row d-flex">
          <small class="text-danger" v-if="errorForm">{{ errorForm }}</small>
        </div>

        <div class="row d-flex justify-content-end">


          <div class="col- mr-2"><b-button variant="secondary" @click="editLineModal = false">Fermer</b-button></div>
          <div class="col- ">
            <b-button type="submit" variant="primary">
              Modifier

            </b-button>
          </div>
        </div>

      </form>

    </b-modal>
    <b-modal id="modal-center" centered size="sm" v-model="commissionModal" title="Modifier le montant de la commission"
      title-class="font-18">
      <form>
        <div class="row">
          <div class="col-md-6">
            <label for="">Commission</label>
          </div>
          <div class="col-md-6">
            <input type="number" v-model="commission" class="form-control" id="" />
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="commissionModal = false">Fermer</b-button>
        <b-button variant="primary" @click="updateCommissiion">
          Modifier
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <b-modal size="xl" id="my-modal" v-model="showModal" title="Les lignes">

      <div>
        <b-table class="lineField" striped responsive hover :items="showLine" :fields="lineField">
          <template #cell(horraire)="data">
            &nbsp;&nbsp;&nbsp;<div v-for="(el, index) in data.value" :key="index" class="d-inline p-2">
              <span v-for="(sub, subIndex) in el" :key="subIndex" class="text-nowrap">
                <span v-if="subIndex === 0">{{ sub }} ➠ </span>
                <span v-else> {{ sub }} </span>
              </span>
              <span v-if="index !== data.value.length - 1"> &nbsp; <br> &nbsp;</span>
            </div>
          </template>
          <template #cell(daysOfWeek)="data">

            <div class="row d-flex justify-content-start">
              <div class="m-0 p-0" v-for="(el, index) in data.value" :key="index">
                <div class="m-0 p-0" v-for="(item, subIndex) in days" :key="subIndex">
                  <div class="col mr-0 pr-1" v-if="el === item.value">{{ item.name }},
                  </div>
                </div>

              </div>

            </div>

          </template>

          <template #cell(isPromo)="data">

            <div class="row d-flex justify-content-center  align-items-md-start text-center">
              <span v-if="data.value">
                OUI
              </span>
              <span v-else>
                NON
              </span>

            </div>

          </template>
          <template #cell(createdAt)="row">
            <div>

              {{ new Date(row.value).toLocaleDateString("fr-FR") }}

            </div>
          </template>



          <template #cell(action)="row">
            <div>

              <i class="fas fa-pencil text-primary" style="cursor: pointer;" @click="editLineOpener(row.item)"></i>
              &nbsp;
              <i v-if="row.item.active" class="fas fa-cancel text-danger" style="cursor: pointer;"
                @click="disableLine(row.item)"></i>
              &nbsp;
              <i v-if="row.item.active === false" class="fas fa-check text-success" style="cursor: pointer;"
                @click="enableLine(row.item)"></i>
              &nbsp;


            </div>
          </template>

        </b-table>
      </div>
      <!-- <table class="table">
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th>Name</th>
                                  <th>Description</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, index) in showLine" :key="index">
                                  <td>{{ item.id }}</td>
                                  <td>{{ item.name }}</td>
                                  <td>{{ item.description }}</td>
                                </tr>
                              </tbody>
                            </table> -->
    </b-modal>

    <b-modal size="xl" id="my-modal" v-model="soldModal" title="Tickets de bus achetés">

      <div class="d-flex justify-content-end">
        <b-pagination v-model="currentPage" :total-rows="rowsSold" :per-page="perPage" aria-controls="my-table"
          class="ml-2" first-number last-number></b-pagination>
      </div>

      <div>
        <b-table class="lineField" striped responsive hover :items="soldData" :fields="soldField" :per-page="perPage"
          :current-page="currentPage" small>

          <template #cell(dateHeureReservation)="data">

            <div class="row d-flex justify-content-md-between m-2 align-items-md-start text-nowrap pb-1">
              <span>
                {{ new Date(data.item.dateHeureReservation).toLocaleDateString("FR-fr") + " à " + new
      Date(data.item.dateHeureReservation).toLocaleTimeString("FR-fr") }}
              </span>

            </div>

          </template>

          <template #cell(line)="data">

            <div class="row d-flex justify-content-md-between  align-items-md-start text-nowrap pb-1">
              <span class="m-2">
                <a href="">{{ data.item.lineId.lieuDepart + " ↣ " + data.item.lineId.lieuArrive }}</a>
              </span>

            </div>
          </template>

          <template #cell(places)="data">
            <div class="row d-flex justify-content-md-between  align-items-md-start text-nowrap pb-1">
              <div class=" row d-flex m-2">
                <div class="col-sm-4" v-for="item in data.item.siege" :key="item">
                  {{ item }},
                </div>

              </div>

            </div>
          </template>


          <template #cell(nombrePlaces)="data">
            <div class="row d-flex justify-content-md-center  align-items-md-start text-nowrap pb-1">
              <span class="m-2">
                {{ data.item.siege.length }}
              </span>

            </div>
          </template>


          <template #cell(user)="data">
            <div class="row d-flex justify-content-md-between m-2  align-items-md-start text-nowrap pb-1">
              <span>
                {{ data.value.firstname + " " + data.value.lastname }}
              </span>

            </div>
          </template>
          <template #cell(telephone)="data">
            <div class="row d-flex justify-content-md-between m-2  align-items-md-start  pb-1">
              <span v-if="data.item.user">
                <a :href="'https://wa.me/' + data.item.user.indicatifTel.replace('+', '') + data.item.user.tel">
                  {{ data.item.user.indicatifTel + " " + data.item.user.tel }} </a>
              </span>

            </div>
          </template>


          <!-- <template #cell(isPromo)="data">
  
              <div class="row d-flex justify-content-center  align-items-md-start text-center">
                <span v-if="data.value">
                  OUI
                </span>
                <span v-else>
                  NON
                </span>
  
              </div>
  
            </template>
            <template #cell(createdAt)="row">
              <div>
  
                {{ new Date(row.value).toLocaleDateString("fr-FR") }}
  
              </div>
            </template>-->



          <template #cell(action)="row">
            <div class="d-flex justify-content-center text-center">

              <a href="" @click.prevent="confirmationModalOpener(row.item)" class="p-2" style="cursor: pointer;"><i
                  class="fas fa-times text-danger"></i></a>
              &nbsp;



            </div>
          </template>

        </b-table>
      </div>

      <div class="d-flex justify-content-end">
        <b-pagination v-model="currentPage" :total-rows="rowsSold" :per-page="perPage" aria-controls="my-table"
          class="ml-2" first-number last-number></b-pagination>
      </div>

    </b-modal>
    <b-modal size="xl" id="my-modal" v-model="buyModal" title="Vendre un ticket" hide-footer>
      <b-card>


        <b-container fluid>

          <div class="row">

            <div class="col-md-4">


              <label for="search" class="form-label">Chercher un utilisateur</label>
              <input id="search" type="text" v-model="recherche" @input="userSearch" class="form-control"
                placeholder="Rechercher..." style="width:80%" />
              <!--  v-if="suggestions.length"  -->
              <ul class="suggestions mt-2 p-2 " style="width:80%; background-color:#eee; border-radius:4px;">

                <li v-for="(user, index) in userFound" :key="index">

                  <div class=" d-block d-flex justify-content-between">
                    <div>{{ user.firstname + " " + user.lastname }}</div>
                    <div><input type="checkbox" class=" d-block " :value="user.id" v-model="selectedUser"
                        @click="selectTarget(user)"></div>

                  </div>


                  <hr class="my-1">
                </li>
                <li class="d-flex justify-content-center">
                  ...
                  <hr class="my-1">
                </li>
              </ul>

            </div>

            <div class="col-md-8">
              <transition name="slide">
                <div v-if="currentStep === 1" class="step-1">
                  <!-- Contenu de la première étape -->
                  <b-card-sub-title> Acheter un billet pour l'utilisateur sélectionnné</b-card-sub-title>
                  <br>
                  <div>
                    <b-table class="lineFieldM mt-2" striped responsive hover :items="showLine" :fields="lineFieldForm">
                      <template #cell(horraire)="data">
                        &nbsp;&nbsp;&nbsp;<div v-for="(el, index) in data.value" :key="index"
                          class="d-inline p-2 pl-0 justify-content-left">
                          <span v-for="(sub, subIndex) in el" :key="subIndex" class="text-nowrap">
                            <span v-if="subIndex === 0">
                              {{ sub }} ➠ </span>
                            <span v-else> {{ sub }} </span>
                          </span>
                          <span v-if="index !== data.value.length - 1"> &nbsp; <br> &nbsp;</span>
                        </div>
                      </template>
                      <template #cell(daysOfWeek)="data">

                        <div class="row d-flex justify-content-start">
                          <div class="m-0 p-0" v-for="(el, index) in data.value" :key="index">
                            <div class="m-0 p-0" v-for="(item, subIndex) in days" :key="subIndex">
                              <div class="col mr-0 pr-1" v-if="el === item.value">{{ item.name }},
                              </div>
                            </div>

                          </div>

                        </div>

                      </template>

                      <template #cell(isPromo)="data">

                        <div class="row d-flex justify-content-center  align-items-md-start text-center">
                          <span v-if="data.value">
                            OUI
                          </span>
                          <span v-else>
                            NON
                          </span>

                        </div>

                      </template>
                      <template #cell(select)="row">

                        <div class="row d-flex justify-content-center  align-items-md-start text-center">
                          <input type="checkbox" v-model="lineIdSelected" :value="row.item.id" :unchecked-value="null"
                            @click="selectLine(row.item)" id="">
                        </div>
                        <!-- <b-form-checkbox
          
         
        ></b-form-checkbox> -->

                      </template>




                      <template #cell(action)="row">
                        <div>

                          <i class="fas fa-pencil text-primary" style="cursor: pointer;"
                            @click="editLineOpener(row.item)"></i>
                          &nbsp;
                          <i v-if="row.item.active" class="fas fa-cancel text-danger" style="cursor: pointer;"
                            @click="disableLine(row.item)"></i>
                          &nbsp;
                          <i v-if="row.item.active === false" class="fas fa-check text-success" style="cursor: pointer;"
                            @click="enableLine(row.item)"></i>
                          &nbsp;


                        </div>
                      </template>

                    </b-table>
                  </div>

                  <div>
                    <div v-for="(seatGroup, type) in groupedSeats" :key="type">
                      <h3>Type {{ type }}</h3>
                      <span v-for="seat in sortSeatGroup(seatGroup)" :key="seat">{{ seat }} </span>
                    </div>
                  </div>
                  <div class="form-group mt-2 mb-2">

                    <label for="date" class="form-label">Date de départ</label>
                    <input type="date" class="form-control" v-model="buy.date">
                  </div>

                  <div class="form-group mt-2 mb-2">
                    <label for="hr" class="form-label">Horraire de départ</label>
                    <select v-model="buy.horraire" id="hr" class="form-control">
                      <option v-for="item in selectedTime" :key="item" :value="item"> {{ item.replace('"', '') }}
                      </option>
                    </select>
                  </div>


                  <!-- <b-table class="searchField" striped responsive hover :items="selectedUser" :fields="userField">
                    


                  </b-table> -->
                  <div class='row d-flex justify-content-center'>
                    <a href="#" class="btn btn-primary" @click.prevent="handleClick">Suivant </a>
                  </div>
                </div>

                <div v-else-if="currentStep === 2" class="step-2">
                  <!-- Contenu de la troisième étape -->
                  <b-card-sub-title>
                    <h2> Nombre de siège: {{ selectedSeat.length }}</h2>
                  </b-card-sub-title>

                  <div class="row d-flex">
                    <div class="d-inline col my-1 mx-2 link">
                      <!-- Loop through seat categories in the desired order -->
                      <div v-for="category in ['A', 'B', 'D', 'E']" :key="category">
                        <span v-if="groupedSeats[category]">
                          <!-- Display category header -->
                          <h3>Type {{ category }}</h3>
                          <!-- Display seats within the category -->
                          <div class="d-flex">
                            <div v-for="seat in sortSeatGroup(groupedSeats[category])" :key="seat"
                              @click="selectSeat(seat)"
                              class="d-flex justify-content-center align-items-center seat-container"
                              :class="{ 'seat-bg': selectedSeat.includes(seat) }">
                              {{ seat }}
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>


                  <!-- <div class="form-group"><label for="" class="form-label"></label><input type="text" class="form-control"></div>
              <div class="form-group"><label for="" class="form-label"></label><input type="text" class="form-control"></div> -->



                  <div class='row d-flex justify-content-center mt-5'>
                    <a href="#" class="btn btn-primary" @click="finalBuy"> Vendre</a>
                  </div>
                </div>
              </transition>
            </div>

          </div>



        </b-container>


      </b-card>

      <!-- <table class="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in showLine" :key="index">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.description }}</td>
        </tr>
      </tbody>
    </table> -->
    </b-modal>

    <b-modal size="sm" id="my-modal" v-model="confirmationModal" title="Annulation du billet" hide-footer>
      <b-card>
        <div class="d-flex flex-column justify-content-center text-center">


          <i class="fas fa-times fa-lg d-block text-danger pb-3"></i>
          <h5 class="pb-3"> Voulez-vous vraiment annuler ce ticket de bus vendu ? </h5>
          <b class="pb-3 text-danger"> Cette action est irréversible !</b>

        </div>
        <div class="d-flex justify-content-between ">

          <a href="" class="d-block btn btn-secondary" @click.prevent="confirmationModal=false">Fermer</a><a href=""
            @click.prevent="cancelTicket()" class="d-block btn btn-danger" @click="cancel">Oui</a>

        </div>
      </b-card>
    </b-modal>
    <!-- -->
  </Layout>
</template>
<style>
thead {
  background: #3AAA35;
}


thead div {
  color: #fff;

}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.lineField td:nth-child(5) {
  max-width: 250px !important;
  white-space: nowrap;
  overflow-x: scroll !important;
  overflow-y: auto !important;
}

.lineField td:nth-child(2) {
  max-width: 200px !important;
  white-space: nowrap;
  overflow: auto;
}


/* td{
  max-height: 100px !important;
  overflow-y:scroll !important ;
} */


/* td {
  max-width: 200px !important;
  white-space: nowrap; 
  overflow: auto;
} */
.bg-color-custom {
  background-color: #3AAA35 !important;
  color: white;

}

.seat-bg {
  background-color: #5bcd3e;
}

.day {
  background-color: rgb(231, 225, 225);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  /* Ajustez la taille selon vos besoins */
  height: 40px;
  border-radius: 50%;
  font-size: 14px;
}

.day:hover {

  color: #fff;
  background-color: rgb(104, 87, 131) !important;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.pac-container {
  z-index: 10000 !important;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.nav-tabs .nav-link {
  background: #dc143c;
  transition: all .5s;
}

.nav-tabs .nav-link a {
  color: #fff;
}

.text-red {
  color: #dc143c;
}

.nav-tabs .nav-link a {
  color: #fff !important;
}

.text-green {
  color: #3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: blue;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35;
  border-bottom: 1px solid blue;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  margin: auto;
  background-color: #d5d5d5;

  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}

input[type="checkbox"]:checked {
  background-color: #5bcd3e;
}

input[type="checkbox"]:checked:after {
  display: block;

}

input[type="checkbox"]:after {
  font-weight: 600;
  font-family: FontAwesome;
  content: "\f00c";
  width: inherit;
  height: inherit;

  font-size: 20px;
  color: white;
  display: none;
}


.seat-container {
  border: 2px solid black;
  width: 35px;
  height: 35px;
  justify-content: center;
  border-radius: 5px;
  margin-right: 5px;
  /* Add margin between seats for spacing */
}

.row.d-flex .d-inline {
  display: flex;
  flex-direction: row;
}
</style>